body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(235, 241, 242, 1) 0%,
    rgba(255, 255, 255, 1) 73%
  );
}
#root,
html,
body {
  margin: 0;
  padding: 0;
  margin: 0;
}
