/** Fonts imports 
  - Inter
  - Roboto
**/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap");

$darkgray: #333333;
$darkblue: #2d383f;
$grayblue: #42505c;
$shockblue: #285cc4;
$pastelblue: #acd2eb;
$dimblue: #e7f2fa;
$lightgray: #e6e6e6;
$lightergray: #f5f5f5;
$sbito: #f9423a;
$sbitolight: #feeceb;

$theme-colors: (
  "darkblue": $darkblue,
  "darkgray": $darkgray,
  "grayblue": $grayblue,
  "shockblue": $shockblue,
  "pastelblue": $pastelblue,
  "dimblue": $dimblue,
  "lightgray": $lightgray,
  "lightergray": $lightergray,
  "sbito": $sbito,
  "sbitolight": $sbitolight,
);

$font-family-sans-serif: "Nunito", sans-serif !default;
$font-family-serif: "Nunito", sans-serif !default;
$font-family-monospace: "Nunito", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
@import "bootstrap";

/* Removes Bootstrap's default button outlines */

.form-control, .form-select, textarea {
  border: 1px solid #ced4da;
  display:inline-block;
  max-width: 100%;
  border-radius:5px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.form-check-input {
  float:none !important;
  width: 1.2em;
  height: 1.2em;
  margin-right:1rem;
  &[type="checkbox"] {
    border-radius: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:checked {
    background-color: $shockblue;
    border-color: $shockblue;
  }
}
a {
  text-decoration: none;
}
@keyframes svgLoad {
  0% {
    opacity: 0;
  }
  20% {
    transform: translateY(4%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.animated-svg-load {
  animation: svgLoad 0.7s ease-out;
}
.btn {
  font-weight: bold;
  color: unset;
  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
  &-primary,
  &-primary:focus {
    color: $darkblue;
    background-color: $pastelblue;
    border: 0;
    opacity: 1;
    &:hover {
      color: $darkblue;
      background-color: $dimblue;
      border: 0;
    }
  }
  /* Fix same bg color when pastelbg */
  &-pastelbg,
  &-pastelbg:focus {
    color: $darkblue;
    background-color: $pastelblue;
    border: 0;
    opacity: 1;
    &:hover {
      color: $darkblue;
      background-color: $lightgray;
      border: 0;
    }
  }
  &[disabled] {
    background-color: $lightgray;
  }
  &-white {
    background-color: white;
    box-shadow: 0 3px 3px rgb(0 0 0 / 8%);
    border: 0;
    transition: all 0.12s;
    &:hover {
      background-color: white;
      transform: translateY(3px);
      box-shadow: none;
      border: 0;
    }
  }
  &-transparent {
    border: 1px solid $pastelblue;
    color: $shockblue;
    transition: background-color 0.2s;
    &:hover {
      color: $shockblue;
      border: 1px solid $dimblue;
      background-color: $dimblue;
    }
    &[disabled] {
      border-color: $lightgray;
    }
  }
}
.divider {
  opacity: 0.2;
  border-bottom: 1px dashed black;
}
